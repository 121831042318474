<script setup lang="ts">
import QuillEditor from "@/components/quill/QuillEditor.vue"
import { type ContactTopic } from "@/model/communication/contact-topic"
import { type ContactTopicGroup } from "@/model/communication/contact-topic-group"
import { type Project } from "@/model/tasks/project"
import IssueIndex from "@/pages/Tasks/Customer/index.vue"
import ResponseAttachments from "@/pages/Tasks/Issue/ResponseAttachments.vue"
import { useAppConfig, useHttpClient } from "@/vf"
import _ from "lodash"
import { ref, watchEffect } from "vue"

const props = defineProps<{
    topicGroup?: ContactTopicGroup
    checkSubmit?: () => boolean
    formClass?: string
    forceTypeOfRequest?: "topic" | "issue"
}>()

const emit = defineEmits<{
    (event: "submitted"): void
}>()

const { appConfig } = useAppConfig()
const http = useHttpClient()

const model = ref({
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    text: "",
    attachments: [],
})

async function submit() {
    if (props.checkSubmit && !props.checkSubmit()) {
        return
    }

    const f = new FormData()
    if (!appConfig.value.account) {
        f.append("firstName", model.value.firstName)
        f.append("lastName", model.value.lastName)
        f.append("email", model.value.email)
    }

    switch (typeOfRequest.value) {
        case "topic":
            f.append("subject", selectedTopic.value.name)
            f.append("topicId", selectedTopic.value.id)
            f.append("taskName", model.value.subject)
            break
    }

    f.append("text", model.value.text)
    let i = 0
    for (const file of model.value.attachments) {
        f.append("attachments[" + i + "]", file)
        i++
    }
    await http.post("/communication-messages/", f)
    emit("submitted")
}

const typeOfRequest = ref<"topic" | "issue">(props.forceTypeOfRequest ?? "topic")
const topicGroups = ref<ContactTopicGroup[]>([])

const selectedTopic = ref<ContactTopic | null>()
const selectedProject = ref<Project | null>()

watchEffect(() => {
    // change type of request to topic when the user logged out
    if (!appConfig.value.account) {
        typeOfRequest.value = "topic"
        selectedProject.value = null
    }
})

// const topicGroup = props.topicGroup
//     ? (await http.get<ContactTopicGroup>(`/communication-messages/topic-group/${props.topicGroup?.id}`)).data
//     : null

if (props.topicGroup) {
    // if a topic group is selected, we only want to show the topics of that group
    topicGroups.value = _.sortBy(
        (await http.get<ContactTopicGroup[]>(`/communication-messages/topic/${props.topicGroup.id}`)).data,
        "name",
    ).map(topicGroup => {
        topicGroup.topics = _.sortBy(topicGroup.topics, "name")
        return topicGroup
    })
} else {
    // otherwise we want to show all topics from all groups
    topicGroups.value = (await http.get<ContactTopicGroup[]>("/communication-messages/topic")).data
}
</script>

<template>
    <div>
        <div :class="props.formClass">
            <form @submit.prevent="submit()">
                <template v-if="!appConfig.account">
                    <div class="form-group d-block">
                        <label for="firstName" class="d-block font-weight-bold">
                            {{ $t("@cms:contactForm.firstName") }}:
                        </label>
                        <input type="text" class="form-control" id="firstName" v-model="model.firstName" required />
                    </div>
                    <div class="form-group d-block">
                        <label for="lastName" class="d-block font-weight-bold">
                            {{ $t("@cms:contactForm.lastName") }}:
                        </label>
                        <input type="text" class="form-control" id="lastName" v-model="model.lastName" required />
                    </div>
                    <div class="form-group d-block">
                        <label for="email" class="d-block font-weight-bold">{{ $t("@cms:contactForm.email") }}:</label>
                        <input type="email" class="form-control" id="email" v-model="model.email" required />
                    </div>
                </template>

                <div class="form-group d-block" v-if="appConfig.account && !forceTypeOfRequest">
                    <label for="typeOfRequest" class="font-weight-bold">
                        {{ $t("@cms:contactForm.typeOfRequest") }}
                    </label>
                    <select id="typeOfRequest" v-model="typeOfRequest" class="form-control">
                        <option value="topic">{{ $t("@cms:contactForm.typeOfRequest.topic") }}</option>
                        <option value="issue">{{ $t("@cms:contactForm.typeOfRequest.issue") }}</option>
                    </select>
                </div>
                <template v-if="typeOfRequest === 'topic'">
                    <div class="form-group d-block">
                        <label for="topic" class="font-weight-bold">{{ $t("@cms:contactForm.topic") }}</label>
                        <select id="topic" v-model="selectedTopic" class="form-control">
                            <optgroup v-for="group of topicGroups" :label="group.name">
                                <option v-for="topic of group.topics" :value="topic">{{ topic.name }}</option>
                            </optgroup>
                        </select>
                    </div>
                    <div class="form-group d-block">
                        <label for="text" class="d-block font-weight-bold">{{ $t("@cms:contactForm.subject") }}:</label>
                        <input type="text" class="form-control" id="subject" v-model="model.subject" />
                    </div>
                    <div class="form-group d-block">
                        <label for="text" class="d-block font-weight-bold">{{ $t("@cms:contactForm.text") }}:</label>
                        <QuillEditor v-model="model.text"></QuillEditor>
                        <!-- <textarea class="form-control" id="text" rows="15" v-model="model.text" required /> -->
                    </div>

                    <div class="form-group d-block">
                        <label class="d-block font-weight-bold">{{ $t("@cms:contactForm.attachments") }}:</label>
                        <ResponseAttachments v-model="model.attachments"></ResponseAttachments>
                    </div>

                    <slot name="buttons" v-bind="{ submit }"></slot>
                </template>
            </form>
        </div>
        <template v-if="typeOfRequest === 'issue'">
            <IssueIndex :allow-new="false"></IssueIndex>
        </template>
    </div>
</template>
