<script setup lang="ts">
// import BaseLogin from "@ApeisiaLoginAccess.Security.baseLogin"
// import { NavbarService } from "resources/elements/navbar/navbar-service"

import { useLegacyHttpClient } from "@/composables/compat/useLegacyHttpClient"
import { useNavbarState } from "@/stores"
import { useAppConfig } from "@/vf"
import { reactive, ref } from "vue"
import { useI18n } from "vue-i18n"
import { useRoute, useRouter } from "vue-router"

const { t } = useI18n()
const navbarState = useNavbarState()
const http = useLegacyHttpClient()
const loading = ref(false)
const router = useRouter()
const route = useRoute()
const { appConfig, refreshAppConfig } = useAppConfig()

const loginError = ref("")
const credentials = reactive({
    username: "",
    password: "",
})

async function submit() {
    loginError.value = ""
    loading.value = true

    try {
        let req = http.createRequest("login").asPost().withContent(credentials)
        await req.send()
        await refreshAppConfig()
        navbarState.openCard(null)

        if (route.meta?.redirectAfterLogin !== false) {
            if (router.hasRoute("@Scoring.Main.index")) {
                await router.push({ name: "@Scoring.Main.index" })
            } else if (router.hasRoute("@App.My.profile")) {
                await router.push({ name: "@App.My.profile" })
            }
        }
    } catch (r) {
        console.log(r.content.error)
        // TODO translations
        if (r.content.error == "User account is disabled.") {
            loginError.value = t("@app:login.inline_login.login_locked")
        } else {
            loginError.value = t("@app:login.inline_login.login_failed")
        }
        setTimeout(() => (loginError.value = ""), 2000)
    }

    loading.value = false
}
</script>

<template>
    <form class="container d-flex align-items-lg-center flex-column flex-lg-row" @submit.prevent="submit()">
        <div class="pr-5 d-block d-sm-none d-xl-block mb-4 mb-lg-0">
            <h4 class="mb-2">{{ $t("@app:login.inline_login.login") }}</h4>
            {{ $t("@app:login.inline_login.my_varsity_europe") }}
        </div>
        <div class="flex-grow-1 d-flex flex-column flex-lg-row">
            <template v-if="!loginError">
                <div class="pr-4 navbar-login-fw">
                    <input
                        type="text"
                        class="form-control"
                        v-model="credentials.username"
                        autocomplete="username"
                        required
                    />
                    {{ $t("@app:login.inline_login.username") }}
                </div>
                <div class="pr-4 navbar-login-fw">
                    <input
                        type="password"
                        class="form-control"
                        v-model="credentials.password"
                        autocomplete="current-password"
                        required
                    />
                    {{ $t("@app:login.inline_login.password") }}
                </div>
            </template>
            <template v-if="loginError">
                <div class="text-danger navbar-login-fw align-self-center">{{ loginError }}</div>
                <div class="navbar-login-fw"></div>
            </template>

            <div>
                <button class="btn btn-primary px-1 px-xl-4" type="submit">
                    {{ $t("login.login") }}
                </button>
            </div>
        </div>
        <div>
            <!--                    <div class="navbar-login-divider"></div>-->
            <a
                @click="navbarState.openCard('registration')"
                class="mx-lg-4 py-3 py-lg-1 d-inline-block"
                id="registration_btn_start"
            >
                {{ $t("@app:login.inline_login.signup") }}
            </a>
        </div>
        <!--                    <div class="navbar-login-divider"></div>-->
        <div>
            <a
                @click="navbarState.openCard('lost-password')"
                class="mx-lg-4 py-3 py-lg-1 d-inline-block"
                id="registration_lost_password"
            >
                {{ $t("@app:login.inline_login.lost_password") }}
            </a>
        </div>
    </form>
</template>

<style scoped>
.navbar-login-fw {
    margin-bottom: 1.5rem;
}

@media (min-width: 992px) {
    .navbar-login-fw {
        width: 16rem;
        margin-bottom: 0;
    }
}
</style>
