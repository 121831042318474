<script setup lang="ts">
import type { ConditionFormData } from "@/components/form"
import ConditionForm from "@/components/form/ConditionForm.vue"
import { useHttpClient } from "@/vf"
import { computed, ref } from "vue"

const props = withDefaults(
    defineProps<{
        modelValue?: ConditionFormData
        preProvidedFields?: string[]
    }>(),
    {
        modelValue: () => ({}),
    },
)
const emit = defineEmits<{
    (e: "signed", form: FormData): void
    (e: "update:modelValue", value: ConditionFormData): void
}>()

const http = useHttpClient()

const modelValue = computed({
    get: () => props.modelValue,
    set: (value: ConditionFormData) => emit("update:modelValue", value),
})

async function save() {
    saving.value = true
    const formData = new FormData(document.getElementById("conditionForm") as HTMLFormElement)

    const pdfData = (
        await http.post<Blob>("/legal/form/pdf/?condition=Staff.PrimaryContract", formData, {
            responseType: "blob",
        })
    ).data

    const form = new FormData()
    form.append("document", pdfData)
    emit("signed", form)
}

const hasEmptySignatureFields = ref(true)
const saving = ref(false)
</script>

<template>
    <ConditionForm
        condition="Staff.PrimaryContract"
        formId="conditionForm"
        @has-empty-signature-fields="hasEmptySignatureFields = $event"
        v-model="modelValue"
        :pre-provided-fields="props.preProvidedFields"
    ></ConditionForm>
    <div class="text-right">
        <button class="btn btn-primary" :disabled="hasEmptySignatureFields || saving" @click="save()">
            <template v-if="saving">
                <i class="fas fa-spinner fa-spin"></i>
            </template>
            <template v-else>
                {{ $t("@tasks:tasks.staff.sign") }}
            </template>
        </button>
    </div>
</template>
