<script setup lang="ts">
import { type AccountContact } from "@/model/app/account-contact"
import { useCountriesStore } from "@/stores/useCountryStore"
import { ref, toRefs, watchEffect } from "vue"

const props = defineProps<{ address?: AccountContact }>()
const { address } = toRefs(props)

const countries = useCountriesStore()

const countryName = ref<string>()

watchEffect(() => {
    countryName.value = ""

    if (address.value?.country) {
        countries.getName(address.value.country).then(name => {
            countryName.value = name
        })
    }
})
</script>

<template>
    <div v-if="address">
        <div>{{ address.addressLine }}</div>
        <div>{{ address.street }} {{ address.streetNumber }}</div>
        <div>{{ address.country }} {{ address.zip }} {{ address.city }}</div>
        <div>{{ countryName }}</div>
    </div>
</template>
