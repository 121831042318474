import { useHttpClient } from "@/vf"
import { defineStore } from "pinia"
import { ref } from "vue"

export const useCountriesStore = defineStore("countries", () => {
    const http = useHttpClient()

    const isLoaded = ref(false)
    const countries = ref(new Map<string, string>())
    const loadingPromise = ref<Promise<void> | null>(null)

    async function getName(code: string) {
        await ensureLoaded()

        return countries.value.get(code) ?? code
    }

    async function ensureLoaded() {
        if (!isLoaded.value) {
            await load()
        }

        if (loadingPromise.value) {
            await loadingPromise.value
        }

        return Promise.resolve()
    }

    async function load() {
        if (loadingPromise.value) {
            return loadingPromise.value
        }

        loadingPromise.value = (async () => {
            const response = await http.get<Record<string, string>>("/country-names/")

            countries.value = new Map(Object.entries(response.data))

            // set loading state
            isLoaded.value = true
            loadingPromise.value = null
        })()
    }

    return {
        isLoaded,
        countries,
        getName,
    }
})
